import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import TechContextProvider from "./context/TechContext";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StackContextProvider from "./context/StackContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <TechContextProvider>
      <StackContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StackContextProvider>
    </TechContextProvider>
  </React.StrictMode>
);
