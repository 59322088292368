import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./pages/Loading";
import "./App.css";

const Home = React.lazy(() =>
  import("./pages/Home" /* webpackChunkName: "Home"*/)
);
const ContactUs = React.lazy(() =>
  import("./pages/ContactUs" /* webpackChunkName: "ContactUs"*/)
);
const NotFound = React.lazy(() =>
  import("./pages/NotFound" /* webpackChunkName: "NotFoundPage"*/)
);
const Portfolio = React.lazy(() =>
  import("./pages/Portfolio" /* webpackChunkName: "PortfolioPage"*/)
);
const Services = React.lazy(() =>
  import("./pages/Services" /* webpackChunkName: "ServicesPage"*/)
);
const Terraform = React.lazy(() =>
  import("./pages/Terraform" /* webpackChunkName: "TerraformPage"*/)
);
const AboutUs = React.lazy(() =>
  import("./pages/AboutUs" /* webpackChunkName: "AboutPage"*/)
);
const AgileDevelopment = React.lazy(() =>
  import(
    "./pages/AgileDevelopment" /* webpackChunkName: "AgileDevelopmentPage"*/
  )
);
const SoftwareArchitecture = React.lazy(() =>
  import(
    "./pages/SoftwareArchitecture" /* webpackChunkName: "SoftwareArchitecturePage"*/
  )
);
const CloudDevelopmentAndDevOps = React.lazy(() =>
  import(
    "./pages/CloudDevelopmentAndDevOps" /* webpackChunkName: "CloudDevelopmentAndDevOpsPage"*/
  )
);
const Docker = React.lazy(() =>
  import("./pages/Docker" /* webpackChunkName: "DockerPage"*/)
);
const Microservices = React.lazy(() =>
  import("./pages/Microservices" /* webpackChunkName: "MicroservicesPage"*/)
);
const Kubernetes = React.lazy(() =>
  import("./pages/Kubernetes" /* webpackChunkName: "KubernetesPage"*/)
);
const Serverless = React.lazy(() =>
  import("./pages/Serverless" /* webpackChunkName: "ServerlessPage"*/)
);
const Cloud = React.lazy(() =>
  import("./pages/Cloud" /* webpackChunkName: "CloudPage"*/)
);
const CloudMigration = React.lazy(() =>
  import("./pages/CloudMigration" /* webpackChunkName: "CloudMigrationPage"*/)
);
const ReArchitecting = React.lazy(() =>
  import("./pages/ReArchitecting" /* webpackChunkName: "ReArchitectingPage"*/)
);

function App() {
  return (
    <div className="relative">
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/terraform-consulting" element={<Terraform />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/agile-development-team"
            element={<AgileDevelopment />}
          />
          <Route
            path="/cloud-development-devops"
            element={<CloudDevelopmentAndDevOps />}
          />
          <Route
            path="/software-architecture"
            element={<SoftwareArchitecture />}
          />
          <Route
            path="/terraform-consulting/cloud-development-devops"
            element={<AgileDevelopment />}
          />
          <Route path="/cloud-migration" element={<CloudMigration />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/serverless" element={<Serverless />} />
          <Route path="/re-architecting" element={<ReArchitecting />} />
          <Route path="/kubernetes" element={<Kubernetes />} />
          <Route path="/microservices" element={<Microservices />} />
          <Route path="/docker" element={<Docker />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default App;
