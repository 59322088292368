import React from "react";

const Loading = () => {
  return (
    <div class="flex justify-center items-center absolute top-0 left-0 right-0 min-h-screen bg-white">
      <div class="animate-spin rounded-full h-36 w-36 border-b-2 border-gray-900"></div>
      <img
        className="absolute animate-pulse top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]"
        src="/assets/images/logo.svg"
        alt=""
      />
    </div>
  );
};

export default Loading;
