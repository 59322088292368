import * as React from "react";

export const StackContext = React.createContext(null);

const StackContextProvider = ({ children }) => {
  const [activeStack, setActiveStack] = React.useState({
    category: "Cloud architecture",
    icon: (
      <svg
        class="sa-services__tile-icon"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.875 16.1875C7.63338 16.1875 7.4375 16.3834 7.4375 16.625C7.4375 16.8666 7.63338 17.0625 7.875 17.0625C8.11662 17.0625 8.3125 16.8666 8.3125 16.625C8.3125 16.3834 8.11662 16.1875 7.875 16.1875V16.1875"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M14 16.625H15.75"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M19.25 16.625H21"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M7.875 23.1875C7.63338 23.1875 7.4375 23.3834 7.4375 23.625C7.4375 23.8666 7.63338 24.0625 7.875 24.0625C8.11662 24.0625 8.3125 23.8666 8.3125 23.625C8.3125 23.3834 8.11662 23.1875 7.875 23.1875V23.1875"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M14 23.625H15.75"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M19.25 23.625H21"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.5 16.625C24.5 18.558 22.933 20.125 21 20.125H7C5.067 20.125 3.5 18.558 3.5 16.625C3.5 14.692 5.067 13.125 7 13.125H21C22.933 13.125 24.5 14.692 24.5 16.625Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.5 23.625C24.5 25.558 22.933 27.125 21 27.125H7C5.067 27.125 3.5 25.558 3.5 23.625C3.5 21.692 5.067 20.125 7 20.125H21C22.933 20.125 24.5 21.692 24.5 23.625Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M26.9733 14C27.3719 12.3077 26.9755 10.5259 25.897 9.16221C24.8185 7.79847 23.1761 7.00215 21.4375 7.00001C20.2848 6.99968 19.1596 7.35181 18.2128 8.00918C17.6049 4.69185 15.1447 2.01916 11.8889 1.13929C8.63314 0.25941 5.16151 1.32899 2.96541 3.88854C0.76931 6.4481 0.23971 10.0419 1.60412 13.1262"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </svg>
    ),

    details: {
      question: "Why build for the cloud?",
      descriptions: [
        "Fact — any app supposed to earn decent money needs the power of cloud environment. That’s because cloud architecture is ready to transform within days to support a reliable and rapid market delivery.",
        "Build any software with any technology and language with scalability, speed, and minimal costs that are unachievable with on-premise servers. Cloud based software development is used by eBay, HotelTonight, Fitbit, or General Electric.",
      ],
      hasList: true,
      hasLearn: true,
      list: {
        title: "Key benefits of cloud-native architecture",
        listItems: [
          "Deploy frequently and roll back easily",
          "Build faster using hundreds of production-ready services",
          "Auto-scale software based on demand",
          "Access and utilize global infrastructure at an affordable price",
        ],
      },
    },
  });

  const [activeDevOps, setActiveDevOps] = React.useState({
    category: "Support Existing Terraform Infrastructure as a Code",
    icon: (
      <svg
        class="sa-services__tile-icon"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <rect
          x="0.75"
          y="1.625"
          width="24.5"
          height="22.75"
          rx="1.5"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></rect>
        <path
          d="M0.75 6.875H25.25"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M13 13.875V17.375"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <rect
          x="11.25"
          y="10.375"
          width="3.5"
          height="3.5"
          rx="0.75"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></rect>
        <rect
          x="11.25"
          y="17.375"
          width="3.5"
          height="3.5"
          rx="0.75"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></rect>
        <rect
          x="4.25"
          y="17.375"
          width="3.5"
          height="3.5"
          rx="0.75"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></rect>
        <rect
          x="18.25"
          y="17.375"
          width="3.5"
          height="3.5"
          rx="0.75"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></rect>
        <path
          d="M11.25 12.125H7.75C6.7835 12.125 6 12.9085 6 13.875V17.375"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M14.75 12.125H18.25C19.2165 12.125 20 12.9085 20 13.875V17.375"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </svg>
    ),

    details: {
      question: "How do we do it?",
      descriptions: [
        "Does your company currently have an existing Terraform Code running locally from your servers? we will support your code using Azure DevOps.",
        "If your only Terraform consultant left the company, the Terraform framework has been created without a CI/CD pipeline, or your company wants to move its resources to the Azure Cloud, it is time to give us a call.",
      ],
      hasList: true,
      hasLearn: true,
      list: {
        title: "Key benefits",
        listItems: [
          "Automate your pipeline end-to-end",
          "Establish controls for who can apply changes",
          "Ensure auditability by tracking all applies",
          "Many components to match your goals",
        ],
      },
    },
  });

  return (
    <StackContext.Provider
      value={{
        activeStack,
        setActiveStack,
        activeDevOps,
        setActiveDevOps,
      }}
    >
      {children}
    </StackContext.Provider>
  );
};

export default StackContextProvider;

export const useStackContext = () => React.useContext(StackContext);
