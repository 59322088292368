import * as React from "react";

export const TechContext = React.createContext({
  name: "",
  image: "",
  description: "",
  slug: "",
});

const TechContextProvider = ({ children }) => {
  const [state, setState] = React.useState({
    name: "TypeScript",
    image: "/assets/images/typescript.svg",
    description:
      "Static typing helps deploys succeed more often and makes refactors easier. For those in need of reliable code.",
    slug: "services/web-development/typescript",
  });

  return (
    <TechContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </TechContext.Provider>
  );
};

export default TechContextProvider;

export const useTechContext = () => React.useContext(TechContext);
